import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBill1Wave, faArrowRightArrowLeft, faBook, faPercent, faTicket, faCircleQuestion, faChevronRight, faChevronLeft, faMoneyBillTransfer, faXmark, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { RouterModule } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from '@componentpath/services/auth.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [FontAwesomeModule, RouterModule],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss'
})
export class SidebarMenuComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
  ) {
    this.checkScreen();
  }

  @Input() active: string = '';

  islogin: boolean = false;
  subscription: Subscription = new Subscription();

  faMoneyBill1Wave: IconProp = faMoneyBill1Wave;
  faArrowRightArrowLeft: IconProp = faArrowRightArrowLeft;
  faBook: IconProp = faBook;
  faPercent: IconProp = faPercent;
  faTicket: IconProp = faTicket;
  faCircleQuestion: IconProp = faCircleQuestion;
  faChevronLeft: IconProp = faChevronLeft;
  faChevronRight: IconProp = faChevronRight;
  faMoneyBillTransfer: IconProp = faMoneyBillTransfer;
  faXmark: IconProp = faXmark;
  faCaretRight: IconProp = faCaretRight;

  sidemenu: boolean = false;

  currentScreenSize: string = '';

  referralRuleJSONData: any = this.data.referralRuleJSONData;
  userGorupData: any = this.auth.userGorupData;

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.99px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.99px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.islogin = v;
      }
    }));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }

  sidemenuToggle(): void {
    this.sidemenu = !this.sidemenu;
  }
}
