<div class="page-search">
    <app-header activeMenu="search" />
    <div class="page-title">
        <div class="container-xxl m-auto">
            Search Result
        </div>
    </div>
    <app-all-games class="all-games" [filterAll]="false" [seeAll]="false" [filterCategory]="false" [loadMore]="true"
        [filterSearch]="true" [minHeight]="true" />
    <app-footer [data]="footerData" />
</div>