import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { Slideshow2Component } from '@template/mps-basic/parts/slideshow2/slideshow2.component';
import { AllGamesComponent } from '@template/mps-basic/parts/all-games/all-games.component';
import { AllProvidersComponent } from '@template/mps-basic/parts/all-providers/all-providers.component';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { db } from '@componentpath/db/db';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, Slideshow2Component, AllGamesComponent, AllProvidersComponent],
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss'
})
export class CategoryComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private request: RequestService,
    private _Activatedroute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  id: any = '';
  subscription: Subscription = new Subscription();
  categoryslideshowJSONData: any = this.data.categoryslideshowJSONData;
  slideshowData: any = null;
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  categoryProviderData: any = this.data.categoryproviderJSONData;
  allProvidersData: any = [];

  gameTypes: Array<any> = [''];
  gameData: Array<any> = [''];

  currentScreenSize: string = '';

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  getGames() {
    db.dbGamesExists().then((e) => {

    })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 12, [''], this.gameTypes, [''], '', 'created_at', 'desc').subscribe({
        next: (v) => {
          this.gameData = v['items'];
          db.addGames(v['items']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription = this._Activatedroute.paramMap.subscribe((params) => {
        this.id = params.get('id');
        if (this.id === '') {
          this.gameTypes = [''];
        } else if (this.id === 'slots') {
          this.gameTypes = ['SL'];
        } else if (this.id === 'live-casino') {
          this.gameTypes = ['LC'];
        } else if (this.id === 'fish-hunter') {
          this.gameTypes = ['FH'];
        } else if (this.id === 'sportbook') {
          this.gameTypes = ['SB'];
        }

        // this.getGames();

        for (const value of this.categoryProviderData) {
          if (value.code === this.id) {
            let providerData = value.providers;
            for (let value of providerData) {
              value.codeQueryParam = '["' + value.code + '"]';
            }
            this.allProvidersData = providerData;
            break;
          }
        }

        this.slideshowData = [];
        const categoryslideshowJSONData: any = this.data.categoryslideshowJSONData;
        if (this.id === 'slots') {
          if (categoryslideshowJSONData['slot'] !== undefined && categoryslideshowJSONData['slot'] !== null) {
            for (const value of categoryslideshowJSONData['slot']) {
              this.slideshowData.push(value);
            }
          }
        } else if (this.id === 'live-casino') {
          if (categoryslideshowJSONData['livecasino'] !== undefined && categoryslideshowJSONData['livecasino'] !== null) {
            for (const value of categoryslideshowJSONData['livecasino']) {
              this.slideshowData.push(value);
            }
          }
        } else if (this.id === 'fish-hunter') {
          if (categoryslideshowJSONData['arcade'] !== undefined && categoryslideshowJSONData['arcade'] !== null) {
            for (const value of categoryslideshowJSONData['arcade']) {
              this.slideshowData.push(value);
            }
          }
        } else if (this.id === 'sportbook') {
          if (categoryslideshowJSONData['sportbook'] !== undefined && categoryslideshowJSONData['sportbook'] !== null) {
            for (const value of categoryslideshowJSONData['sportbook']) {
              this.slideshowData.push(value);
            }
          }
        }
      });

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (this.id === 'slots' && value.type.toLowerCase() === 'slot' || this.id === 'live-casino' && value.type.toLowerCase() === 'live-casino' || this.id === 'fish-hunter' && value.type.toLowerCase() === 'fish-hunter' || this.id === 'sportbook' && value.type.toLowerCase() === 'sportbook') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.subscription) this.subscription.unsubscribe();
    }
  }
}
