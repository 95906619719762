@if(currentScreenSize === 'desktop') {
<div class="header-container {{currentScreenSize}}">
    <div class="header-container-inner">
        <div class="header-container-inner-wrapper container-xxl m-auto">
            <div class="header-middle">
                <div class="header-middle-left">
                    <div class="header-middle-left-inner">
                        <a routerLink="/" class="link-casino">
                            <img [src]="websiteJSONData.header.logo" />
                        </a>
                    </div>
                </div>
                <div class="header-middle-center">
                    <div class="latest-news">
                        <div class="latest-news-left">
                            <img src="../assets/orianna/images/icon-bullhorn.png" alt="latest-news" /> NEWS
                        </div>
                        <div class="latest-news-right">
                            <div class="latest-news-right-inner">
                                @if(newstickerData !== null) {
                                @for (item of newstickerData; track item._id; let idx = $index, e = $even) {
                                @if (item.link_type !== 0) {
                                <a class="newsticker-item" href={{item.target_url}} target="_blank">{{item.title}}</a>
                                } @else {
                                <span class="newsticker-item">{{item.title}}</span>
                                }
                                @if (idx < (newstickerData.length - 1)) { <span class="marquee-sep"></span>
                                    }
                                    }
                                    }
                            </div>
                        </div>
                    </div>
                    <div class="time">
                        <fa-icon [icon]="faClock"></fa-icon> {{ time }}
                    </div>
                </div>
                <div class="header-middle-right">
                    <!-- <div ngbDropdown class="language" [class.language-login]="islogin">
                        <div ngbDropdownToggle>
                            EN
                        </div>
                        <div ngbDropdownMenu>
                            <button ngbDropdownItem>EN</button>
                            <button ngbDropdownItem>ID</button>
                        </div>
                    </div> -->
                    @if(!islogin) {
                    <a routerLink="/register">
                        <button class="btn-register">
                            REGISTER
                        </button>
                    </a>
                    <a (click)="redirectLogin()">
                        <button class="btn-login">
                            LOGIN
                        </button>
                    </a>
                    } @else {
                    <div ngbDropdown class="profile-dropdown">
                        <div ngbDropdownToggle>
                            <div class="profile">
                                <div class="name">{{profile.login_id
                                    }}</div>
                                <div class="balance">
                                    @if(balance < 0) { <div class="spinner-border p-2 spinner-border-sm">
                                </div>
                                } @else {
                                IDR {{balance | formatNumberId}}
                                }
                            </div>
                        </div>
                        <img src="../assets/orianna/images/avatar.png" alt="avatar" />
                    </div>
                    <div ngbDropdownMenu>
                        <a routerLink="/profile">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faUser"></fa-icon> Profile
                            </button>
                        </a>
                        <a routerLink="/statement">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faBook"></fa-icon> Statement
                            </button>
                        </a>
                        <a routerLink="/deposit">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faWallet"></fa-icon> Deposit
                            </button>
                        </a>
                        <a routerLink="/withdrawal">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faDownload"></fa-icon> Withdrawal
                            </button>
                        </a>
                        <a routerLink="/promotion">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faPercent"></fa-icon> Promosi
                            </button>
                        </a>
                        @if(!islogin && referralRuleJSONData['1'] !== undefined || islogin &&
                        referralRuleJSONData[userGorupData['rank']]
                        !== undefined) {
                        <a routerLink="/referral">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faNetworkWired"></fa-icon> Referral
                            </button>
                        </a>
                        }
                        <a routerLink="/profile" [queryParams]="{ action: 'change-password' }">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faLock"></fa-icon> Ganti Password
                            </button>
                        </a>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="logout()">
                            <fa-icon [icon]="faArrowRightFromBracket"></fa-icon> Keluar
                        </button>
                    </div>
                </div>
                }
            </div>
        </div>
        @if(!islogin) {
        <div class="header-bottom">
            <a href="#">
                Forgot password?
            </a>
        </div>
        } @else {
        <div class="header-bottom">

        </div>
        }
    </div>
</div>
<div class="menu-top-container">
    <div class="menu-top container-xxl m-auto">
        <div class="menu-provider2" [class.d-grid]="showMenuSlots.menu || showMenuSlots.submenu"
            [class.d-none]="!showMenuSlots.menu && !showMenuSlots.submenu" (mouseenter)="showMenu('slots', 'submenu')"
            (mouseleave)="hideMenu('slots', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['slots'].providers; track item._id;) {
            <a [routerLink]="['/category','slots']" [queryParams]="{provider: item.code, time: getTime}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="item.image_url" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-provider2" [class.d-grid]="showMenuLiveCasino.menu || showMenuLiveCasino.submenu"
            [class.d-none]="!showMenuLiveCasino.menu && !showMenuLiveCasino.submenu"
            (mouseenter)="showMenu('live-casino', 'submenu')" (mouseleave)="hideMenu('live-casino', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['live-casino'].providers; track item._id;) {
            <a [routerLink]="['/category','live-casino']" [queryParams]="{provider: item.code}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="item.image_url" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-provider2" [class.d-grid]="showMenuFishHunter.menu || showMenuFishHunter.submenu"
            [class.d-none]="!showMenuFishHunter.menu && !showMenuFishHunter.submenu"
            (mouseenter)="showMenu('fish-hunter', 'submenu')" (mouseleave)="hideMenu('fish-hunter', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['fish-hunter'].providers; track item._id;) {
            <a [routerLink]="['/category','fish-hunter']" [queryParams]="{provider: item.code}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="item.image_url" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-provider2" [class.d-grid]="showMenuSportbook.menu || showMenuSportbook.submenu"
            [class.d-none]="!showMenuSportbook.menu && !showMenuSportbook.submenu"
            (mouseenter)="showMenu('sportbook', 'submenu')" (mouseleave)="hideMenu('sportbook', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['sportbook'].providers; track item._id;) {
            <a [routerLink]="['/category','sportbook']" [queryParams]="{provider: item.code}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="item.image_url" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-top-left">
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'home'">
                <a routerLink="/">
                    <fa-icon [icon]="faHouse"></fa-icon>
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'search'">
                <a [routerLink]="['/search']">
                    ALL GAMES
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'slots'"
                (mouseenter)="showMenu('slots', 'menu')" (mouseleave)="hideMenu('slots', 'menu')">
                <a [routerLink]="['/category','slots']">
                    SLOT
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'live-casino'"
                (mouseenter)="showMenu('live-casino', 'menu')" (mouseleave)="hideMenu('live-casino', 'menu')">
                <a [routerLink]="['/category','live-casino']">
                    CASINO
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'fish-hunter'"
                (mouseenter)="showMenu('fish-hunter', 'menu')" (mouseleave)="hideMenu('fish-hunter', 'menu')">
                <a [routerLink]="['/category','fish-hunter']">
                    ARCADE
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'sportbook'"
                (mouseenter)="showMenu('sportbook', 'menu')" (mouseleave)="hideMenu('sportbook', 'menu')">
                <a [routerLink]="['/category','sportbook']">
                    SPORTBOOK
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'promotion'">
                <a [routerLink]="['/promotion']">
                    PROMOTION
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'deposit'">
                <a [routerLink]="['/deposit']">
                    DEPOSIT
                </a>
            </div>
        </div>
        <div class="menu-top-right">
            <input type="text" class="form-control" placeholder="Search games.." [defaultValue]="searchValue"
                (keyup.enter)="filterSearchChange($event)" (keyup)="updateSearchValue($event)">
            <fa-icon [icon]="faMagnifyingGlass" (click)="filterSearchChange($event)"></fa-icon>
        </div>
    </div>
</div>
</div>
}
@else if(currentScreenSize === 'mobile') {
<div class="header-container-mobile {{currentScreenSize}}">
    <div class="header-container-mobile-inner" [class.header-container-mobile-inner-fixed]="sidenav">
        <a routerLink="/" class="link-casino">
            <img [src]="websiteJSONData.header.logo" />
        </a>
        <!-- <div class="search-area">
            <input type="text" class="form-control" placeholder="Search" [defaultValue]="searchValue"
                (keyup.enter)="filterSearchChange($event)" (keyup)="updateSearchValue($event)">
            <fa-icon [icon]="faMagnifyingGlass" (click)="filterSearchChange($event)"></fa-icon>
        </div> -->
        <div class="btn-sidenav" (click)="sidenavToggle()">
            @if(!sidenav) {
            <fa-icon [icon]="faBars"></fa-icon>
            } @else {
            <fa-icon [icon]="faXmark"></fa-icon>
            }
        </div>
    </div>
    <div class="latest-news">
        <div class="latest-news-left">
            <img src="../assets/orianna/images/icon-bullhorn.png" alt="latest-news" />
        </div>
        <div class="latest-news-right">
            <div class="latest-news-right-inner">
                @if(newstickerData !== null) {
                @for (item of newstickerData; track item._id; let idx = $index, e = $even) {
                @if (item.link_type !== 0) {
                <a class="newsticker-item" href={{item.target_url}} target="_blank">{{item.title}}</a>
                } @else {
                <span class="newsticker-item">{{item.title}}</span>
                }
                @if (idx < (newstickerData.length - 1)) { <span class="marquee-sep"></span>
                    }
                    }
                    }
            </div>
        </div>
    </div>
    <div class="sidenav" [class.show]="sidenav">
        @if(islogin) {
        <a routerLink="/profile">
            <div class="profile">
                <img src="../assets/orianna/images/avatar.png" alt="avatar" />
                <div class="profile">
                    <div class="name">{{profile.login_id
                        }}</div>
                    <div class="balance">
                        @if(balance < 0) { <div class="spinner-border p-2 spinner-border-sm">
                    </div>
                    } @else {
                    IDR {{balance | formatNumberId}}
                    }
                </div>
            </div>
    </div>
    </a>
    <div class="sidenav-sep"></div>
    }
    <!-- <div ngbDropdown>
            <div ngbDropdownToggle>
                LANGUAGE
            </div>
            <div ngbDropdownMenu>
                <button ngbDropdownItem>EN</button>
                <button ngbDropdownItem>ID</button>
            </div>
        </div> -->
    <a routerLink="/" class="sidenav-item" (click)="sidenavToggle()">
        HOME
    </a>
    @if(!islogin) {
    <a routerLink="/register" class="sidenav-item" (click)="sidenavToggle()">
        REGISTER
    </a>
    }
    <div class="sidenav-sep"></div>
    <a [routerLink]="['/category','slots']" class="sidenav-item" (click)="sidenavToggle()">
        SLOT
    </a>
    <a [routerLink]="['/category','live-casino']" class="sidenav-item" (click)="sidenavToggle()">
        CASINO
    </a>
    <a [routerLink]="['/category','fish-hunter']" class="sidenav-item" (click)="sidenavToggle()">
        FISH HUNTER
    </a>
    <a [routerLink]="['/category','sportbook']" class="sidenav-item" (click)="sidenavToggle()">
        SPORTBOOK
    </a>
    <div class="sidenav-sep"></div>
    <a routerLink="/statement" class="sidenav-item" (click)="sidenavToggle()">
        STATEMENT
    </a>
    <a routerLink="/deposit" class="sidenav-item" (click)="sidenavToggle()">
        DEPOSIT
    </a>
    <a routerLink="/withdrawal" class="sidenav-item" (click)="sidenavToggle()">
        WITHDRAW
    </a>
    <a routerLink="/promotion" class="sidenav-item" (click)="sidenavToggle()">
        PROMOTION
    </a>
    @if(!islogin && referralRuleJSONData['1'] !== undefined || islogin && referralRuleJSONData[userGorupData['rank']]
    !== undefined) {
    <a routerLink="/referral" class="sidenav-item" (click)="sidenavToggle()">
        REFERRAL
    </a>
    }
    <a routerLink="/contact" class="sidenav-item" (click)="sidenavToggle()">
        KONTAK
    </a>
    @if(!islogin) {
    <button class="login" (click)="redirectLogin()">
        LOGIN
    </button>
    } @else() {
    <button class="btn btn-danger btn-block" (click)="logout()">
        LOGOUT
    </button>
    }
</div>
<div class="menu-footer-mobile">
    @if(!islogin) {
    <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'login'">
        <a (click)="redirectLogin()">
            <fa-icon [icon]="faRightToBracket"></fa-icon>
            <div class="text">Masuk</div>
            <div class="dot">.</div>
        </a>
    </div>
    } @else {
    <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'deposit'">
        <a routerLink="/deposit">
            <fa-icon [icon]="faMoneyBillTransfer"></fa-icon>
            <div class="text">Deposit</div>
            <div class="dot">.</div>
        </a>
    </div>
    }
    <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'promotion'">
        <a routerLink="/promotion">
            <fa-icon [icon]="faGift"></fa-icon>
            <div class="text">Promosi</div>
            <div class="dot">.</div>
        </a>
    </div>
    <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'home'">
        <a routerLink="/">
            <fa-icon [icon]="faHouse"></fa-icon>
            <div class="text">Home</div>
            <div class="dot">.</div>
        </a>
    </div>
    <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'contact'">
        <a routerLink="/contact">
            <fa-icon [icon]="faPhone"></fa-icon>
            <div class="text">Kontak</div>
            <div class="dot">.</div>
        </a>
    </div>
    <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'profile'">
        <a routerLink="/profile">
            <fa-icon [icon]="faUser"></fa-icon>
            <div class="text">Profil</div>
            <div class="dot">.</div>
        </a>
    </div>
</div>
</div>
}