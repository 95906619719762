import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { db } from '@componentpath/db/db';
import { AllGamesComponent } from '@template/mps-basic/parts/all-games/all-games.component';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, AllGamesComponent],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private request: RequestService
  ) { }

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  allGamesData: any = [];
  providers: Array<any> = [];

  getGames(providers: Array<any>) {
    db.dbGamesExists().then((e) => {

    })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 20, this.providers, [''], [''], '', 'created_at', 'desc').subscribe({
        next: (v) => {
          this.allGamesData = v['items'];
          db.addGames(v['items']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'search') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }
}
