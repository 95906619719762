import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankPlayerPopupComponent } from '@template/mps-basic/parts/bank-player-popup/bank-player-popup.component';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-withdrawal',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FormsModule],
  templateUrl: './withdrawal.component.html',
  styleUrl: './withdrawal.component.scss'
})
export class WithdrawalComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }
  private modalService = inject(NgbModal);

  currentScreenSize: string = '';

  bank_info: any = {
    player: null,
    company: null
  };
  userProfileData: any = this.auth.userProfileData;
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  playerBankList: any = null;
  companyBankList: any = null;

  isSubmit: boolean = false;
  successMessage: string = '';
  errorMessage: string = '';

  amount: string = '';
  transaction_note: string = '';

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.99px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.99px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  popupOpen(): void {
    const activeModal = this.modalService.open(BankPlayerPopupComponent, { size: 'lg', keyboard: false, centered: true });
    activeModal.componentInstance.bankCurrent = this.bank_info.player;
    activeModal.result.then(
      (result) => {
        this.updateBankInfo('player', result);
      }
    );
  }

  popupClose() {
    this.modalService.dismissAll();
  }

  updateBankInfo(type: string, value: any): void {
    if (this.playerBankList !== null && this.companyBankList !== null) {
      if (type === 'player') {
        if (this.playerBankList.data.records.length > 0) {
          this.bank_info.company = null;
          this.bank_info.player = value;
          for (const value of this.companyBankList.data['1']) {
            if (this.bank_info.player.bank === value.bank) {
              this.bank_info.company = value;

              break;
            }
          }
        }
      } else {
        if (this.companyBankList.data['1'].length > 0) {
          this.bank_info.company = value;

          this.bank_info.player = null;
          for (const value of this.playerBankList.data.records) {
            if (this.bank_info.company.bank === value.bank) {
              this.bank_info.player = value;
              break;
            }
          }
        }
      }
    }
  }

  updateBankInfoInit(): void {
    if (this.playerBankList !== null && this.companyBankList !== null) {
      if (this.playerBankList.data.records.length > 0) {
        this.bank_info.player = this.playerBankList.data.records[0];
        for (const value of this.companyBankList.data['1']) {
          if (this.bank_info.player.bank === value.bank) {
            this.bank_info.company = value;
            break;
          }
        }
      }
    }
  }

  filterNumber(event: any): void {
    event.target.value = event.target.value.replace(/\D/g, '');
  }

  amountKeyup(event: any): void {
    this.amount = event.target.value.replaceAll(/\./g, '');

    if (this.amount !== '') {
      event.target.value = this.numberFormat(parseInt(this.amount));
    } else {
      event.target.value = this.amount;
    }
  }

  numberFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(value);
  }

  createWithdrawSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.amount) {
        this.errorMessage = 'Jumlah is Required.';
        this.isSubmit = false;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return;
      } else {
        this.request.createWithdraw(this.auth.sessionValue, this.bank_info.player._id, this.amount.toString(), this.transaction_note).subscribe({
          next: (v: any) => {
            if (v.code == 200) {
              this.isSubmit = false;
              this.successMessage = 'Create Withdraw is Success.';
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          },
          error: (e) => {
            this.isSubmit = false;
            this.errorMessage = e.error.messages[0].message;
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
        next: (v) => {
          this.companyBankList = v;
          this.updateBankInfoInit();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
      this.request.getBankPlayerList(this.auth.sessionValue, this.userProfileData._id).subscribe({
        next: (v) => {
          this.playerBankList = v;
          this.updateBankInfoInit();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'withdrawal') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }
}
