import { isPlatformBrowser } from '@angular/common';
import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleXmark, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-card-game',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './card-game.component.html',
  styleUrl: './card-game.component.scss'
})
export class CardGameComponent {
  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  @ViewChild('cardGame') cardGame!: ElementRef;
  @ViewChild('cardTitleInner') cardTitleInner!: ElementRef;

  cardGameWidth: number = 0;
  cardTitleInnerWidth: number = 0;

  @Input() preload: boolean = false;
  @Input() image: string = '';
  @Input() gacor: string = '';
  @Input() favourite: boolean = false;
  @Input() name: string = '';
  @Input() provider: string = '';
  @Input() percent: number = 0;
  @Input() pattern: string = '';
  @Input() check1: boolean = false;
  @Input() check2: boolean = false;
  @Input() check3: boolean = false;
  @Input() dc: boolean = false;

  faStar: IconProp = faStar;
  faClock: IconProp = faClock;
  faCircleXmark: IconProp = faCircleXmark;
  faCircleCheck: IconProp = faCircleCheck;

  currentScreenSize: string = '';

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  updateWidth() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.cardGameWidth > 300) {
        if (window.innerWidth >= 992) {
          this.cardGameWidth = (this.cardGameWidth - 80) / 6;
        } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
          this.cardGameWidth = (this.cardGameWidth - 60) / 4;
        } else if (window.innerWidth < 768) {
          this.cardGameWidth = (this.cardGameWidth - 40) / 3;
        }
        // } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
        //   this.cardGameWidth = (this.cardGameWidth - 40) / 3;
        // } else if (window.innerWidth < 576) {
        //   this.cardGameWidth = this.cardGameWidth;
        // }
      }
    }
  }

  ngAfterViewInit() {
    this.cardGameWidth = this.cardGame.nativeElement.clientWidth;
    this.cardTitleInnerWidth = this.cardTitleInner.nativeElement.clientWidth;
    this.updateWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.cardGameWidth = this.cardGame.nativeElement.clientWidth;
    this.cardTitleInnerWidth = this.cardTitleInner.nativeElement.clientWidth;
    this.updateWidth();
  }
}
