<div class="modal-header">
    <h4 class="modal-title">UBAH KODE REFERRAL</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center">
        <div class="bg-blue p-4 mb-3 rounded">
            @if(successMessage !== '') {
            <div class="alert alert-success p-2" role="alert">
                {{successMessage}}
            </div>
            }
            @if(errorMessage !== '') {
            <div class="alert alert-danger p-2" role="alert">
                {{errorMessage}}
            </div>
            }
            <form (submit)="editReferralSubmit()">
                <div class="input-group mb-4">
                    <input type="text" class="form-control bg-blue2 is-valid" placeholder="Kode Referral Baru" oninput="this.value = (this.value).toUpperCase().replace(/[^a-zA-Z0-9_\.\-\,_~]*$/g, '');" pattern="[a-zA-Z0-9]*" [min]="8" [max]="16" [minlength]="8" [maxlength]="16" name="total"
                    [(ngModel)]="referralCode">
                    <div class="valid-feedback">
                        Minimal 8 karakter & Maksimal 16 karakter.
                    </div>
                </div>
                <div class="text-end">
                    <button class="btn btn-sm btn-yellow2 py-1 px-4" type="submit" [disabled]="editSubmit">
                        @if(editSubmit) {
                        <div class="spinner-border p-2 spinner-border-sm"></div>
                        } @else {
                        SIMPAN
                        }
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>