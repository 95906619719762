<div class="provider">
    <div class="container-xxl m-auto">
        <div class="button-title">
            PROVIDER
        </div>
        <div class="provider-inner">
            @for (item of data; track item._id;) {
            <div class="provider-inner-item">
                <a routerLink="/search" [queryParams]="{ providers: item.codeQueryParam }">
                    <img [src]="item.image_url" [alt]="item.name" />
                </a>
            </div>
            }
        </div>
    </div>
</div>