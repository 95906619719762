<div class="modal-header">
    <h4 class="modal-title">PIN</h4>
</div>
<div class="modal-body">
    <div class="align-items-center pin-main p-5">
        <div class="title mb-4">PIN</div>
        @if(errorMessage !== '') {
        <div class="alert alert-danger p-2" role="alert">
            {{errorMessage}}
        </div>
        }
        <form (submit)="login()">
            <div class="pin-container">
                <input maxlength="1" type="password" class="form-control" #pin1 (input)="pinInput($event,1) "
                    (paste)="pinPaste($event)">
                <input maxlength="1" type="password" class="form-control" #pin2 (input)="pinInput($event,2)"
                    (keydown)="pinKeydown($event,2)">
                <input maxlength="1" type="password" class="form-control" #pin3 (input)="pinInput($event,3)"
                    (keydown)="pinKeydown($event,3)">
                <input maxlength="1" type="password" class="form-control" #pin4 (input)="pinInput($event,4)"
                    (keydown)="pinKeydown($event,4)">
                <input maxlength="1" type="password" class="form-control" #pin5 (input)="pinInput($event,5)"
                    (keydown)="pinKeydown($event,5)">
                <input maxlength="1" type="password" class="form-control" #pin6 (input)="pinInput($event,6)"
                    (keydown)="pinKeydown($event,6)">
            </div>

            <div class="mb-3 d-flex flex-column justify-content-center login-bottom">
                <button type="submit" class="btn btn-primary btn-block mt-3" [disabled]="loginSubmit">
                    @if(loginSubmit) {
                    <div class="spinner-border p-2 spinner-border-sm"></div>
                    } @else {
                    LOGIN
                    }
                </button>
                <button class="btn btn-danger btn-block mt-2" (click)="logout()">
                    LOGOUT
                </button>
            </div>
        </form>
    </div>
</div>