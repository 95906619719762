<div class="modal-header">
    <h4 class="modal-title">UBAH PASSWORD</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center pin-main p-5">
        @if(successMessage !== '') {
        <div class="alert alert-success p-2" role="alert">
            {{successMessage}}
        </div>
        }
        @if(errorMessage !== '') {
        <div class="alert alert-danger p-2" role="alert">
            {{errorMessage}}
        </div>
        }
        <form (submit)="editPasswordSubmit()">
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">Password*</label>
                <div class="col-12 col-lg-8 password">
                    <input type="password" class="form-control" [type]="isPasswordVisible[0] ? 'text' : 'password'"
                        placeholder="Password" [(ngModel)]="password[0]" name="password[0]">
                    <fa-icon (click)="togglePaswordVisibility(0)" [icon]="isPasswordVisible[0] ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">New Password*</label>
                <div class="col-12 col-lg-8 password">
                    <input type="password" class="form-control" [type]="isPasswordVisible[1] ? 'text' : 'password'"
                        placeholder="New Password" [(ngModel)]="password[1]" name="password[1]">
                    <fa-icon (click)="togglePaswordVisibility(1)" [icon]="isPasswordVisible[1] ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">Confirm Password*</label>
                <div class="col-12 col-lg-4 password">
                    <input type="password" class="form-control" [type]="isPasswordVisible[2] ? 'text' : 'password'"
                        placeholder="Confirm Password" [(ngModel)]="password[2]" name="password[2]">
                    <fa-icon (click)="togglePaswordVisibility(2)" [icon]="isPasswordVisible[2] ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>
            </div>
            <div class="row mt-4 mt-lg-0">
                <div class="col-12 col-lg-4"></div>
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="btn btn-block btn-black" (click)="popupClose()">
                                CANCEL
                            </div>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-block btn-blue" type="submit" [disabled]="isSubmit">
                                @if(isSubmit) {
                                <div class="spinner-border p-2 spinner-border-sm"></div>
                                } @else {
                                SUBMIT
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>