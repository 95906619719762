import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { FOOTERJSON, PROMOJSON, PROVIDERJSON, CATEGORYPROVIDERJSON, SLIDESHOWJSON, WEBSITECONFIG, WEBSITEJSON, NEWSTICKERJSON, BANKREGISTERJSON, REFERRALRULEJSON, TRANSACTIONCONFIGJSON, CATEGORYSLIDESHOWJSON, POPUPSJSON } from '@componentsrc/express.tokens';
import { WebsiteConfig } from '@componentpath/schema/interface.global';

const websiteConfigKey = makeStateKey<WebsiteConfig>('websiteConfig');
const websiteJSONKey = makeStateKey<Record<string, any>>('websiteJSON');
const slideshowJSONKey = makeStateKey<Record<string, any>>('slideshowJSON');
const popupsJSONKey = makeStateKey<Record<string, any>>('popupsJSON');
const referralRuleJSONKey = makeStateKey<Record<string, any>>('referralRuleJSON');
const providerJSONKey = makeStateKey<Record<string, any>>('providerJSON');
const categoryproviderJSONKey = makeStateKey<Record<string, any>>('categoryproviderJSON');
const categoryslideshowJSONKey = makeStateKey<Record<string, any>>('categoryslideshowJSON');
const promoJSONKey = makeStateKey<Record<string, any>>('promoJSON');
const footerJSONKey = makeStateKey<Record<string, any>>('footerJSON');
const newsTickerJSONKey = makeStateKey<Record<string, any>>('newsTickerJSON');
const bankRegisterJSONKey = makeStateKey<Record<string, any>>('bankRegisterJSON');
const transactionConfigJSONKey = makeStateKey<Record<string, any>>('transactionConfigJSON');

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public websiteConfigData: WebsiteConfig = {};
  public websiteJSONData: Record<string, any> = {};
  public slideshowJSONData: Record<string, any> = {};
  public popupsJSONData: Record<string, any> = {};
  public referralRuleJSONData: Record<string, any> = {};
  public providerJSONData: Record<string, any> = {};
  public categoryproviderJSONData: Record<string, any> = {};
  public categoryslideshowJSONData: Record<string, any> = {};
  public promoJSONData: Record<string, any> = {};
  public footerJSONData: Record<string, any> = {};
  public newstickerJSONData: Record<string, any> = {};
  public bankRegisterJSONData: Record<string, any> = {};
  public transactionConfigJSONData: Record<string, any> = {};
  public themeName?: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(WEBSITECONFIG) private websiteConfig: WebsiteConfig,
    @Optional() @Inject(WEBSITEJSON) private websiteJSON: Record<string, any>,
    @Optional() @Inject(SLIDESHOWJSON) private slideshowJSON: Record<string, any>,
    @Optional() @Inject(POPUPSJSON) private popupsJSON: Record<string, any>,
    @Optional() @Inject(REFERRALRULEJSON) private referralRuleJSON: Record<string, any>,
    @Optional() @Inject(PROVIDERJSON) private providerJSON: Record<string, any>,
    @Optional() @Inject(CATEGORYPROVIDERJSON) private categoryproviderJSON: Record<string, any>,
    @Optional() @Inject(CATEGORYSLIDESHOWJSON) private categoryslideshowJSON: Record<string, any>,
    @Optional() @Inject(PROMOJSON) private promoJSON: Record<string, any>,
    @Optional() @Inject(FOOTERJSON) private footerJSON: Record<string, any>,
    @Optional() @Inject(NEWSTICKERJSON) private newsTickerJSON: Record<string, any>,
    @Optional() @Inject(BANKREGISTERJSON) private bankRegisterJSON: Record<string, any>,
    @Optional() @Inject(TRANSACTIONCONFIGJSON) private transactionConfigJSON: Record<string, any>,
    private transferState: TransferState
  ) { }

  public getFileJSON = () => {
    if (isPlatformServer(this.platformId)) {
      this.websiteConfigData = this.websiteConfig;
      this.transferState.set(websiteConfigKey, this.websiteConfig);

      this.websiteJSONData = this.websiteJSON;
      this.transferState.set(websiteJSONKey, this.websiteJSON);

      this.slideshowJSONData = this.slideshowJSON;
      this.transferState.set(slideshowJSONKey, this.slideshowJSON);

      this.popupsJSONData = this.popupsJSON;
      this.transferState.set(popupsJSONKey, this.popupsJSON);

      this.referralRuleJSONData = this.referralRuleJSON;
      this.transferState.set(referralRuleJSONKey, this.referralRuleJSON);

      this.providerJSONData = this.providerJSON;
      this.transferState.set(providerJSONKey, this.providerJSON);

      this.categoryproviderJSONData = this.categoryproviderJSON;
      this.transferState.set(categoryproviderJSONKey, this.categoryproviderJSON);

      this.categoryslideshowJSONData = this.categoryslideshowJSON;
      this.transferState.set(categoryslideshowJSONKey, this.categoryslideshowJSON);

      this.promoJSONData = this.promoJSON;
      this.transferState.set(promoJSONKey, this.promoJSON);

      this.footerJSONData = this.footerJSON;
      this.transferState.set(footerJSONKey, this.footerJSON);

      this.newstickerJSONData = this.newsTickerJSON;
      this.transferState.set(newsTickerJSONKey, this.newsTickerJSON);

      this.bankRegisterJSONData = this.bankRegisterJSON;
      this.transferState.set(bankRegisterJSONKey, this.bankRegisterJSON);

      this.transactionConfigJSONData = this.transactionConfigJSON;
      this.transferState.set(transactionConfigJSONKey, this.transactionConfigJSON);

    } else if (isPlatformBrowser(this.platformId)) {

      this.websiteConfigData = this.transferState.get<WebsiteConfig>(websiteConfigKey, {});
      this.websiteJSONData = this.transferState.get<Record<string, any>>(websiteJSONKey, {});
      this.slideshowJSONData = this.transferState.get<Record<string, any>>(slideshowJSONKey, {});
      this.popupsJSONData = this.transferState.get<Record<string, any>>(popupsJSONKey, {});
      this.referralRuleJSONData = this.transferState.get<Record<string, any>>(referralRuleJSONKey, {});
      this.providerJSONData = this.transferState.get<Record<string, any>>(providerJSONKey, {});
      this.categoryproviderJSONData = this.transferState.get<Record<string, any>>(categoryproviderJSONKey, {});
      this.categoryslideshowJSONData = this.transferState.get<Record<string, any>>(categoryslideshowJSONKey, {});
      this.promoJSONData = this.transferState.get<Record<string, any>>(promoJSONKey, {});
      this.footerJSONData = this.transferState.get<Record<string, any>>(footerJSONKey, {});
      this.newstickerJSONData = this.transferState.get<Record<string, any>>(newsTickerJSONKey, {});
      this.bankRegisterJSONData = this.transferState.get<Record<string, any>>(bankRegisterJSONKey, {});
      this.transactionConfigJSONData = this.transferState.get<Record<string, any>>(transactionConfigJSONKey, {});
    }
  };
}
