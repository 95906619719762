import { Component, inject, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';

@Component({
  selector: 'app-referral-popup-detail',
  standalone: true,
  imports: [FormatNumberIdPipe],
  templateUrl: './referral-popup-detail.component.html',
  styleUrl: './referral-popup-detail.component.scss'
})
export class ReferralPopupDetailComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService
  ) { }

  private modal = inject(NgbActiveModal);
  popupClose() {
    this.modal.close();
  }

  getReferralDownline: any = null;
  page: number = 1;
  pagination: any = [];

  pageChange(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    const userProfileData: any = this.auth.userProfileData;
    this.request.getReferralDownline(this.auth.sessionValue, userProfileData._id, { page: 1, limit: 50 }).subscribe({
      next: (v) => {
        this.getReferralDownline = v;
        console.log(this.getReferralDownline);
        // this.pagination = [];
        // for (let i = 1; i <= this.claimedReferral.data.max_page; i++) {
        //   this.pagination.push(i);
        // }
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getData();
    }
  }
}
