import { Component } from '@angular/core';
import { HomeComponent } from "@template/mps-basic/home/home.component";

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    imports: [HomeComponent]
})
export class LoginComponent {

}
