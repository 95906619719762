import { Component, PLATFORM_ID, Inject, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';

@Component({
  selector: 'app-pin-edit-popup',
  standalone: true,
  imports: [FormsModule, FontAwesomeModule],
  templateUrl: './pin-edit-popup.component.html',
  styleUrl: './pin-edit-popup.component.scss'
})
export class PinEditPopupComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService
  ) { }
  private modalService = inject(NgbModal);

  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;

  successMessage: string = '';
  errorMessage: string = '';

  isPinVisible: Array<any> = [false, false, false];
  pin: Array<any> = ['', '', ''];

  isSubmit: boolean = false;

  togglePinVisibility(index: number): void {
    this.isPinVisible[index] = !this.isPinVisible[index];
  }

  public pinInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]+/g, '');
  }

  public editPinSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.pin[0] || !this.pin[1] || !this.pin[2]) {
        setTimeout(() => {
          this.errorMessage = 'PIN or New PIN or Confirm PIN is Required.';
          this.isSubmit = false;
        }, 500);
        return;
      } else {
        if (this.pin[1] !== this.pin[2]) {
          setTimeout(() => {
            this.errorMessage = 'New PIN & Confirm PIN is Not Same.';
            this.isSubmit = false;
          }, 500);
          return;
        } else {
          const userProfileData: any = this.auth.userProfileData;
          this.request.editPIN(this.auth.sessionValue, userProfileData._id, this.pin[1], this.pin[0]).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.successMessage = 'Edit PIN is Success.';
                setTimeout(() => {
                  this.modalService.dismissAll();
                }, 1000);
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
            }
          })
        }
      }
    }
  }

  popupClose() {
    this.modalService.dismissAll();
  }
}
