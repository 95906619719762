<div class="page-promotion ticker-none">
    <app-header activeMenu="promotion" ></app-header>
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'promotion'" ></app-sidebar-menu>
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    PROMOTION
                </div>
                @if(promo !== null) {
                <a routerLink="/promotion">
                    <button class="back btn btn-primary text-white">
                        << Back </button>
                </a>
                <div class="promotion-container">
                    <img class="my-4" [src]="promo.banner_url" [alt]="promo.title" />
                    <div class="promotion-container-description p-4">
                        <div class="title">
                            {{promo.title}}
                        </div>
                        <div class="content" [innerHTML]="promo.content"></div>
                    </div>
                </div>
                }
                @else {
                <div class="promotion-container row">
                    @for (item of promoData; track item._id;) {
                    @if(item.global === false) {
                    <div class="col-lg-6 col-12 mb-4">
                        <a routerLink="/promotion" [queryParams]="{ '_id': item._id }">
                            <img [src]="item.thumbnail_url" [alt]="item.title" />
                        </a>
                    </div>
                    }
                    }
                </div>
                }
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" ></app-footer>
</div>