<div class="page-category">
    <app-header [activeMenu]="id" />

    <div class="page-title">
        <div class="row container-xxl m-auto">
            <div class="col-12 col-lg-3 my-2 d-flex flex-column justify-content-center text-center">
                @if(id === 'slots' || id === 'live-casino' || id === 'fish-hunter' || id === 'sportbook') {
                @if(id === 'slots') {
                <div class="page-title-category">
                    <div class="ratio ratio-1x1 mb-2">
                        <img src="../template/mps-basic/assets/orianna/images/Title Slot.webp" />
                    </div>
                    <div class="page-title-category-inner">
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/pragmatic play copy.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/pg soft.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/yellowbat.webp" />
                        </div>
                    </div>
                </div>
                } @else if(id === 'live-casino') {
                <div class="page-title-category">
                    <div class="ratio ratio-1x1 mb-2">
                        <img src="../template/mps-basic/assets/orianna/images/Casino Title.webp" />
                    </div>
                    <div class="page-title-category-inner">
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/pragmatic play copy.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/evolution-gaming.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/wm.webp" />
                        </div>
                    </div>
                </div>
                }@else if(id === 'fish-hunter') {
                <div class="page-title-category">
                    <div class="ratio ratio-1x1 mb-2">
                        <img src="../template/mps-basic/assets/orianna/images/Arcade title.webp" />
                    </div>
                    <div class="page-title-category-inner">
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/Playstar Logo.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/bt.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/yellowbat.webp" />
                        </div>
                    </div>
                </div>
                }@else {
                <div class="page-title-category">
                    <div class="ratio ratio-1x1 mb-2">
                        <img src="../template/mps-basic/assets/orianna/images/Sportbook Title.webp" />
                    </div>
                    <div class="page-title-category-inner">
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/sbobet.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/saba sport logo.webp" />
                        </div>
                        <div
                            class="page-title-category-inner-item  d-flex flex-column justify-content-center text-center">
                            <img src="../template/mps-basic/assets/orianna/images/ug.webp" />
                        </div>
                    </div>
                </div>
                }
                }
            </div>
            @if(currentScreenSize === 'desktop') {
            <div class="col-12 col-lg-9">
                <app-slideshow2 [data]="slideshowData" />
            </div>
            }
        </div>
    </div>

    <app-all-games [data]="gameData" [gameTypes]="gameTypes" [filterAll]="false" [seeAll]="false"
        [filterCategory]="true" [providerLists]="allProvidersData" [loadMore]="true"
        [lobby]="id === 'live-casino' ? true : false" />
    <app-all-providers [data]="allProvidersData" />

    <app-footer [data]="footerData" />
</div>