import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, PLATFORM_ID, Inject, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-slideshow2',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [],
  templateUrl: './slideshow2.component.html',
  styleUrl: './slideshow2.component.scss'
})
export class Slideshow2Component implements OnInit {
  @Input() data: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('data : ', this.data);
      register();
    }
  }
}
