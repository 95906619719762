import { Component, PLATFORM_ID, Inject, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';

@Component({
  selector: 'app-password-edit-popup',
  standalone: true,
  imports: [FormsModule, FontAwesomeModule],
  templateUrl: './password-edit-popup.component.html',
  styleUrl: './password-edit-popup.component.scss'
})
export class PasswordEditPopupComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService
  ) { }
  private modalService = inject(NgbModal);

  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;

  successMessage: string = '';
  errorMessage: string = '';

  isPasswordVisible: Array<any> = [false, false, false];
  password: Array<any> = ['', '', ''];

  isSubmit: boolean = false;

  togglePaswordVisibility(index: number): void {
    this.isPasswordVisible[index] = !this.isPasswordVisible[index];
  }

  editPasswordSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.password[0] || !this.password[1] || !this.password[2]) {
        setTimeout(() => {
          this.errorMessage = 'Password or New Password or Confirm Password is Required.';
          this.isSubmit = false;
        }, 500);
        return;
      } else {
        if (this.password[1] !== this.password[2]) {
          setTimeout(() => {
            this.errorMessage = 'New Password & Confirm Password is Not Same.';
            this.isSubmit = false;
          }, 500);
          return;
        } else {
          const userProfileData: any = this.auth.userProfileData;
          this.request.editPassword(this.auth.sessionValue, userProfileData._id, this.password[1], this.password[0]).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.successMessage = 'Edit Password is Success.';
                setTimeout(() => {
                  this.modalService.dismissAll();
                }, 1000);
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
            }
          })
        }
      }
    }
  }

  popupClose() {
    this.modalService.dismissAll();
  }
}
