<div class="popular-games align-items-center">
    <div class="container-xxl popular-game-container">
        @if(currentScreenSize === 'desktop') {
        <div class="popular-games-left">
            <img src="../template/mps-basic/assets/orianna/images/bg-popular.png">
        </div>
        }
        <div class="popular-games-right">
            <a routerLink="/search" [queryParams]="{ sort: 'popularity_point' }">
                <div class="button-title">POPULAR GAMES</div>
            </a>
            <div class="games">
                @for (item of data; track item.id;) {
                <a [routerLink]="['/play',item.id]" role="button" target="_blank">
                    <img [src]="item.image" [alt]="item.title">
                </a>
                }
            </div>
            <div class="btn btn-blue see-all">
                <a routerLink="/search" [queryParams]="{ sort: 'popularity_point' }">
                    SEE ALL <fa-icon [icon]="faAnglesRight"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</div>