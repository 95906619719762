<div class="modal-header">
    <h4 class="modal-title">UBAH PIN</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center pin-main p-5">
        @if(successMessage !== '') {
        <div class="alert alert-success p-2" role="alert">
            {{successMessage}}
        </div>
        }
        @if(errorMessage !== '') {
        <div class="alert alert-danger p-2" role="alert">
            {{errorMessage}}
        </div>
        }
        <form (submit)="editPinSubmit()">
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">PIN*</label>
                <div class="col-12 col-lg-8 password">
                    <input maxlength="6" type="password" class="form-control"
                        [type]="isPinVisible[0] ? 'text' : 'password'" placeholder="PIN" [(ngModel)]="pin[0]"
                        name="pin[0]" (input)="pinInput($event)">
                    <fa-icon (click)="togglePinVisibility(0)" [icon]="isPinVisible[0] ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">New PIN*</label>
                <div class="col-12 col-lg-8 password">
                    <input maxlength="6" type="password" class="form-control"
                        [type]="isPinVisible[1] ? 'text' : 'password'" placeholder="New PIN" [(ngModel)]="pin[1]"
                        name="pin[1]" (input)="pinInput($event)">
                    <fa-icon (click)="togglePinVisibility(1)" [icon]="isPinVisible[1] ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">Confirm PIN*</label>
                <div class="col-12 col-lg-8 password">
                    <input maxlength="6" type="password" class="form-control"
                        [type]="isPinVisible[2] ? 'text' : 'password'" placeholder="Confirm PIN" [(ngModel)]="pin[2]"
                        name="pin[2]" (input)="pinInput($event)">
                    <fa-icon (click)="togglePinVisibility(2)" [icon]="isPinVisible[2] ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>
            </div>
            <div class="row mt-4 mt-lg-0">
                <div class="col-12 col-lg-4"></div>
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="btn btn-block btn-black" (click)="popupClose()">
                                CANCEL
                            </div>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-block btn-blue" type="submit" [disabled]="isSubmit">
                                @if(isSubmit) {
                                <div class="spinner-border p-2 spinner-border-sm"></div>
                                } @else {
                                SUBMIT
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>