<div class="modal-header">
    <h4 class="modal-title">DAFTAR AKUN BANK</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="align-items-center pin-main p-2 p-lg-5">
        @if(successMessage !== '') {
        <div class="alert alert-success p-2" role="alert">
            {{successMessage}}
        </div>
        }
        @if(errorMessage !== '') {
        <div class="alert alert-danger p-2" role="alert">
            {{errorMessage}}
        </div>
        }
        <form (submit)="createBankAccount()">
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">Pilih Bank*</label>
                <div class="col-12 col-lg-8">
                    <select class="form-select" [(ngModel)]="bank" name="bank">
                        <option value="">Pilih Bank</option>
                        @for (item of bankList; track item._id;) {
                        <option [value]="item._id">{{item.name}}</option>
                        }
                    </select>
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">Nomor Rekening*</label>
                <div class="col-12 col-lg-8">
                    <input type="text" class="form-control" placeholder="Nomor Rekening" [(ngModel)]="account_number"
                        name="account_number">
                </div>
            </div>
            <div class="form-group row mb-3">
                <label class="col-12 col-lg-4 text-lg-end label text-white">Penerima*</label>
                <div class="col-12 col-lg-8">
                    <input type="text" class="form-control" placeholder="Penerima" [(ngModel)]="account_name"
                        name="account_name" disabled>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4"></div>
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <div class="btn btn-block btn-black" (click)="popupClose()">
                                CANCEL
                            </div>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-block btn-blue" type="submit" [disabled]="isSubmit">
                                @if(isSubmit) {
                                <div class="spinner-border p-2 spinner-border-sm"></div>
                                } @else {
                                SUBMIT
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>