import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';

@Component({
  selector: 'app-wall',
  standalone: true,
  imports: [],
  templateUrl: './wall.component.html',
  styleUrl: './wall.component.scss'
})
export class WallComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  checkSession(session:string){
    this.request.getSessionUser(session).subscribe({
      next: (v) => {
        console.log(v);
        if(v['code'] === 200){
          v.data.hide_pin = true;
          this.auth.setLogin(v.data, {});
        }
      },
      error:(error)=>{
        console.log(error);
        this.router.navigate(['/login']);
      },
      complete: ()=>{}
    })
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log(this.route.snapshot.paramMap.get('session'));
      if(this.route.snapshot.paramMap.get('session') !== null){
        this.checkSession(this.route.snapshot.paramMap.get('session') as string);
      } else{
        this.router.navigate(['/login']);
      }
    }
  }
}
