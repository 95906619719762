<div class="page-game">
    <div class="min-height">
        <div class="container-xxl invisible" #allGamesInner></div>
        @if(error) {
        <div class="d-flex flex-column text-center justify-content-center py-4">
            Game is Not Found.
        </div>
        } @else {
        <div class="container-game d-block">
            @if(preload) {
            <div class="p-4 text-center">
                <div class="spinner-border p-2 spinner-border-sm"></div>
            </div>
            } @else {
            @if(game.game_type !== 'SB' && game.game_type !== 'ES') {
            <div class="container-fullscreen">
                <!-- <div class="ratio ratio-16x9"> -->
                    @if(provider.publisher_code !== 'pg') {
                    <iframe width="100%" height="100%" frameBorder="0" [src]="gameURL"></iframe>
                    } @else {
                    <iframe width="100%" height="100%" frameBorder="0" [srcdoc]="gameURL"></iframe>
                    }
                <!-- </div> -->
            </div>
            } @else {
            <div class="container-fullscreen">
                <div class="ratio ratio-16x9">
                    <iframe width="100%" height="100%" frameBorder="0" [src]="gameURL"></iframe>
                </div>
            </div>
            }
            }
        </div>
        }
    </div>
</div>