<swiper-container class="swiper-container" pagination="true" effect="coverflow" grab-cursor="true"
    centered-slides="true" slides-per-view="auto" coverflow-effect-rotate="0" coverflow-effect-stretch="500"
    coverflow-effect-depth="100" coverflow-effect-modifier="1.5" coverflow-effect-slide-shadows="true"
    autoplay-delay="2500" autoplay-pause-on-mouse-enter="true">
    @if(data.length > 0) {
    @for (item of data; track item.order;) {
    <swiper-slide class="swiper-item">
        @if(item.target_url !== '' && item.target_url !== '#') {
        <a [href]="item.target_url" target="_blank">
            <img [src]="item.asset_url" [title]="item.title" />
        </a>
        } @else {
        <img [src]="item.asset_url" [title]="item.title" />
        }
    </swiper-slide>
    }
    } @else {
    <swiper-slide class="swiper-item">
        <img src="../template/mps-basic/assets/orianna/images/Slot Default.webp" title="Slot" />
    </swiper-slide>
    <swiper-slide class="swiper-item">
        <img src="../template/mps-basic/assets/orianna/images/Casino Default.webp" title="Casino" />
    </swiper-slide>
    <swiper-slide class="swiper-item">
        <img src="../template/mps-basic/assets/orianna/images/Arcade Default.webp" title="Arcade" />
    </swiper-slide>
    }
</swiper-container>