import { Component } from '@angular/core';

@Component({
  selector: 'app-login-popup',
  standalone: true,
  imports: [],
  templateUrl: './loading-popup.component.html',
  styleUrl: './loading-popup.component.scss'
})
export class LoadingPopupComponent {
  constructor() { }
}
