<div>
    @if(lobby) {
    <div class="lobby">
        <div class="container-xxl m-auto">
            @if(currentScreenSize === 'desktop') {
            <div class="lobby-inner">
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== ''" (click)="lobbyClick('All')">
                    <img src="../template/mps-basic/assets/orianna/images/All Lobby.webp" />
                    <div class="lobby-item-inner">
                        ALL<br />
                        LOBBY
                    </div>
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'GAMESHOW'"
                    (click)="lobbyClick('GAMESHOW')">
                    <img src="../template/mps-basic/assets/orianna/images/Gameshows Lobby.webp" />
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'BLACKJACK'"
                    (click)="lobbyClick('BLACKJACK')">
                    <img src="../template/mps-basic/assets/orianna/images/Blackjack lobby.webp" />
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'ROULETTE'"
                    (click)="lobbyClick('ROULETTE')">
                    <img src="../template/mps-basic/assets/orianna/images/Roulette Lobby.webp" />
                </div>
                <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'BACCARAT'"
                    (click)="lobbyClick('BACCARAT')">
                    <img src="../template/mps-basic/assets/orianna/images/Baccarat Lobby.webp" />
                </div>
            </div>
            } @else {
            <swiper-container class="swiper-container" loop="true" speed="1000" autoplay-delay="3000"
                autoplay-pause-on-mouse-enter="true" pagination="true" grab-cursor="true" centered-slides="false"
                slides-per-view="2.5" space-between="20">
                <swiper-slide class="swiper-item">
                    <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== ''" (click)="lobbyClick('All')">
                        <img src="../template/mps-basic/assets/orianna/images/All Lobby.webp" />
                        <div class="lobby-item-inner">
                            ALL<br />
                            LOBBY
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-item">
                    <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'GAMESHOW'"
                        (click)="lobbyClick('GAMESHOW')">
                        <img src="../template/mps-basic/assets/orianna/images/Gameshows Lobby.webp" />
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-item">
                    <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'BLACKJACK'"
                        (click)="lobbyClick('BLACKJACK')">
                        <img src="../template/mps-basic/assets/orianna/images/Blackjack lobby.webp" />
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-item">
                    <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'ROULETTE'"
                        (click)="lobbyClick('ROULETTE')">
                        <img src="../template/mps-basic/assets/orianna/images/Roulette Lobby.webp" />
                    </div>
                </swiper-slide>
                <swiper-slide class="swiper-item">
                    <div class="lobby-item ratio ratio-1x1" [class.active]="tags[0]=== 'BACCARAT'"
                        (click)="lobbyClick('BACCARAT')">
                        <img src="../template/mps-basic/assets/orianna/images/Baccarat Lobby.webp" />
                    </div>
                </swiper-slide>
            </swiper-container>
            }
        </div>
    </div>
    }
    <div class="all-games" [ngClass]="{'min-height': minHeight === true}">
        <div class="container-xxl m-auto">
            @if(filterAll === true) {
            <div class="filter">
                <div class="filter-left">
                    @if(currentScreenSize === 'desktop') {
                    <span href="#" [ngClass]="{'active': type === '' }" (click)="filterAllClick('')">
                        ALL
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'slots' }" (click)="filterAllClick('slots')">
                        SLOTS
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'live-casino' }"
                        (click)="filterAllClick('live-casino')">
                        CASINO
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'fish-hunter' }"
                        (click)="filterAllClick('fish-hunter')">
                        FISH
                    </span>
                    <span href="#" [ngClass]="{'active': type === 'sportbook' }" (click)="filterAllClick('sportbook')">
                        SPORTBOOK
                    </span>
                    }
                    @if(currentScreenSize === 'mobile') {
                    <select class="form-select" (change)="selectFilterAll($event)">
                        <option value="">All</option>
                        <option value="slots">SLOTS</option>
                        <option value="live-casino">CASINO</option>
                        <option value="fish-hunter">FISH</option>
                        <option value="sportbook">SPORTBOOK</option>
                    </select>
                    }
                </div>
                <div class="filter-right">
                    <div class="input-group">
                        <span class="input-group-text">
                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                        </span>
                        <input (keyup)="filterSearchChangeCat($event)" type="text" class="form-control"
                            placeholder="Search..">
                    </div>
                </div>
            </div>
            }
            @if(filterCategory === true) {
            <div class="filter">
                <div class="filter-left">
                    <span href="#" [ngClass]="{'active': sortBy === 'name'}" (click)="filterSortClickCat('name')">
                        ALL
                    </span>
                    <span href="#" [ngClass]="{'active': sortBy === 'latest' || sortBy === undefined}"
                        (click)="filterSortClickCat('latest')">
                        NEW
                    </span>
                    <span href="#" [ngClass]="{'active': sortBy === 'popularity'}"
                        (click)="filterSortClickCat('popularity')">
                        POPULAR
                    </span>
                    &nbsp;
                </div>
                <div class="filter-right filter-right2">
                    <div class="filter-right-item">
                        <div class="btn-reset" (click)="reset()">
                            Reset
                        </div>
                    </div>
                    <div class="filter-right-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{ arrayProvidersCat.length === 0 ? "PROVIDER": arrayProvidersCat.length === 1 ?
                                providersLabel :
                                arrayProvidersCat.length > 1 ? arrayProvidersCat.length+" Selected" : "PROVIDER"}}
                            </button>
                            <div ngbDropdownMenu>
                                @for (option of providerLists; track option;) {
                                <div class="pe-2">
                                    <mat-checkbox id="{{option['code']}}" color="primary"
                                        [checked]="arrayProvidersCat.includes(option['code'])"
                                        (change)="onSelectDropdownValueProviderCat($event)">{{option.name}}</mat-checkbox>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="filter-right-item">
                        <div class="input-group">
                            <span class="input-group-text">
                                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                            </span>
                            <input (keyup)="filterSearchChangeCat($event)" [(ngModel)]="searchValue" type="text"
                                class="form-control" placeholder="Search..">
                        </div>
                    </div>
                </div>
            </div>
            }
            @if(filterSearch === true) {
            <div class="filter filter-search">
                <div class="filter-left">
                    <div class="filter-left-item">
                        <div ngbDropdown autoClose="outside">
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{ selectedOptions.length === 0 ? 'CATEGORIES': selectedOptions.length === 1 ?
                                gameTypesLabel :
                                selectedOptions.length > 1 ? selectedOptions.length+" Selected" : 'CATEGORIES'}}
                            </button>
                            <div ngbDropdownMenu>
                                @for (option of typeLists; track option;) {
                                <div class="pe-2">
                                    <mat-checkbox id="{{option['code_small']}}" color="primary"
                                        [checked]="option['checked']" (change)="onSelectDropdownValue($event)">{{
                                        option.title.en === 'Fish Hunter' ? 'Arcade' : option.title.en }}</mat-checkbox>
                                </div>

                                <!-- <label ngbDropdownItem for="{{option['code_small']}}" class="d-flex gap-2" [class.active]="option['checked']"
                                    (change)="onSelectDropdownValue($event)">
                                    <input id="{{option['code_small']}}" type="checkbox" [checked]="option['checked']" />
                                    {{ option.title.en }}
                                </label> -->
                                }
                            </div>
                        </div>
                    </div>
                    <div class="filter-left-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{ tags.length === 0 ? 'TYPE': tags.length === 1 ? tagsLabel :
                                tags.length > 1 ? tags.length+" Selected" : 'TYPE'}}
                            </button>
                            <div ngbDropdownMenu>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('JACKPOT')"
                                        (change)="filterTagClick('JACKPOT')">Jackpot</mat-checkbox>
                                </div>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('MEGAWHEEL')"
                                        (change)="filterTagClick('MEGAWHEEL')">Megawheel</mat-checkbox>
                                </div>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('BUYIN')"
                                        (change)="filterTagClick('BUYIN')">Buy In</mat-checkbox>
                                </div>
                                <div class="pe-2">
                                    <mat-checkbox color="primary" [checked]="tags.includes('BONANZA')"
                                        (change)="filterTagClick('BONANZA')">Bonanza</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="filter-left-item">
                        <div ngbDropdown>
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{ selectedOptionsProvider.length === 0 ? "PROVIDER": selectedOptionsProvider.length ===
                                1 ? providersLabel :
                                selectedOptionsProvider.length > 1 ? selectedOptionsProvider.length+" Selected" :
                                "PROVIDER"}}
                            </button>
                            <div ngbDropdownMenu>
                                @for (option of providerListsAll; track option;) {
                                <div class="pe-2">
                                    <mat-checkbox id="{{option['code']}}" color="primary" [checked]="option['checked']"
                                        (change)="onSelectDropdownValueProvider($event)">{{option.name}}</mat-checkbox>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="filter-left-item">
                        <div ngbDropdown autoClose="true">
                            <button type="button" class="btn" ngbDropdownToggle>
                                {{sortByLabel}}
                            </button>
                            <div ngbDropdownMenu>
                                <div class="sort-item" (click)="filterSortClick('latest')"
                                    [ngClass]="{'active': qParams['sort'] === 'latest'}">Latest</div>
                                <div class="sort-item" (click)="filterSortClick('popularity')"
                                    [ngClass]="{'active': qParams['sort'] === 'popularity' || qParams['sort'] === undefined}">
                                    Popular</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filter-search-item">
                    <div class="filter-right-item">
                        <div class="input-group">
                            <input (keyup)="filterSearchChange($event)" type="text" class="form-control"
                                placeholder="Search" [defaultValue]="searchValue">
                            <span class="input-group-text">
                                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div class="all-games-inner" #allGamesInner>
                @if(preload) {
                <app-card-game [preload]="true" />
                <app-card-game [preload]="true" />
                <app-card-game [preload]="true" />
                <app-card-game [preload]="true" />
                <app-card-game [preload]="true" />
                <app-card-game [preload]="true" />
                } @else {
                @for (item of data; track item.id;) {
                <div class="all-games-inner-item" role="button" [ngStyle]="{'max-width': allGamesInnerItemWidth+'px'}">
                    <a [routerLink]="['/play',item.id]" target="_blank">
                        <app-card-game [image]="item.image" [gacor]="item.gacor" [favourite]="item.favourite"
                            [name]="item.title" [provider]="item.provider_name" [percent]="item.rtp"
                            [pattern]="item.pattern" [check1]="item.check1" [check2]="item.check2"
                            [check3]="item.check3" [dc]="item.dc" />
                    </a>
                </div>
                }
                }
            </div>
            @if(seeAll === true && data.length === 12) {
            <div class="see-all text-center">
                <a routerLink="/search">
                    SEE ALL <fa-icon [icon]="faAnglesRight"></fa-icon>
                </a>
            </div>
            }
            <!-- @if(loadMore === true && page < totalPages) { <div #scrollAnchor id="load-more"
                class="load-more text-center" (click)="loadMoreClick()">
                LOAD MORE <fa-icon [icon]="faAnglesRight"></fa-icon>
        </div>
        } -->
            @if(autoScroll) {
            <div #scrollAnchor class="scroll-anchor"></div>
            }
        </div>
    </div>
</div>