import { Component, PLATFORM_ID, Inject, Input } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgbAccordionModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Input() data: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
}
