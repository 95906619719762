import { Component } from '@angular/core';
import { HomeComponent } from "@template/mps-basic/home/home.component";

@Component({
    selector: 'app-register',
    standalone: true,
    templateUrl: './register.component.html',
    styleUrl: './register.component.scss',
    imports: [HomeComponent]
})
export class RegisterComponent {

}
