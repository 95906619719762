import { Component } from '@angular/core';
import { HomeComponent } from "@template/mps-basic/home/home.component";

@Component({
  selector: 'app-login-input',
  standalone: true,
  templateUrl: './pin.component.html',
  styleUrl: './pin.component.scss',
  imports: [HomeComponent],
})
export class PinComponent {

}
