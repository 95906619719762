<div class="modal-header">
    <h4 class="modal-title">PILIH REKENING TUJUAN</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="row p-2">
        <div class="promotion-filter col-12">
            <div class="row">
                <div class="col-6">
                    <div class="input-group">
                        <input type="text" class="form-control input-blue text-white" placeholder="Search Bank"
                            (keydown.enter)="keywordSubmit($event)">
                        <span class="input-group-text input-blue text-white">
                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        @if(bankList !== null) {
        @for (item of bankShow; track item;) {
        <div class="col-6 col-lg-4 px-4 pb-3 promotion-item"
            [class.promotion-active]="bankCurrent !== null && bankCurrent._id === item._id" (click)="bankClick(item)">
            <div class="row bg-white br-2 py-3 px-2 rounded">
                <div class="bank-left col-lg-5 col-12 mb-lg-0 mb-2 d-flex flex-column justify-content-center">
                    <img [src]="item.system_bank.images.small" />
                </div>
                <div class="col-lg-7 col-12 d-flex flex-column justify-content-center">
                    <div class="bank-account-name">
                        {{item.account_name}}
                    </div>
                    <div class="bank-account-number">
                        {{item.account_number}}
                    </div>
                </div>
            </div>
        </div>
        }
        }
        @if(qrisEnable) {
        <div class="col-6 col-lg-4 px-4 pb-3 promotion-item"
            [class.promotion-active]="bankCurrent !== null && bankCurrent._id === 'qris'" (click)="bankClick(qrisData)">
            <div class="row bg-white br-2 py-3 px-2 rounded">
                <div class="bank-left col-lg-5 col-12 mb-lg-0 mb-2 d-flex flex-column justify-content-center">
                    <div class="ratio ratio-1x1">
                        <div class="d-flex flex-column justify-content-center">
                            <img src="../../../template/mps-basic/assets/orianna/images/payment-qris.webp" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-12 d-flex flex-column justify-content-center">
                    <div class="bank-account-name">
                        QRIS
                    </div>
                    <div class="bank-account-number">

                    </div>
                </div>
            </div>
        </div>
        }
    </div>
</div>