import { Component, Input, inject, PLATFORM_ID, Inject, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { QRCodeModule } from 'angularx-qrcode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Router } from "@angular/router";

@Component({
  selector: 'app-bank-player-popup',
  standalone: true,
  imports: [FontAwesomeModule, QRCodeModule],
  templateUrl: './qris-popup.component.html',
  styleUrl: './qris-popup.component.scss'
})
export class QrisPopupComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public request: RequestService,
    public auth: AuthService,
    private router: Router,
  ) { }

  @Input() qrisCallback: any = null;
  public total: string = '';
  public qrCodeDownloadLink: string = "";
  public minutes: number = 0;
  public seconds: number = 0;
  public minutesShow: string = '';
  public secondsShow: string = '';
  private intervalId: any;

  modal = inject(NgbActiveModal);

  onChangeURL(url: any) {
    this.qrCodeDownloadLink = url;
  }

  popupClose() {
    this.modal.close();
    this.router.navigate(['/transaction']);
  }

  ngOnInit() {
    this.total = Intl.NumberFormat('id-ID').format(this.qrisCallback.amount);

    dayjs.extend(utc);
    const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
    const time2 = dayjs(this.qrisCallback.expired_at);
    const diffInSeconds = time2.diff(time1, 'second');
    this.minutes = Math.floor(diffInSeconds / 60);
    this.seconds = diffInSeconds % 60;
    this.startCountdown();
  }

  checkCountdown() {
    this.minutesShow = String(this.minutes).padStart(2, '0');
    this.secondsShow = String(this.seconds).padStart(2, '0');
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      dayjs.extend(utc);
      const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
      const time2 = dayjs(this.qrisCallback.expired_at);
      const diffInSeconds = time2.diff(time1, 'second');
      this.minutes = Math.floor(diffInSeconds / 60);
      this.seconds = diffInSeconds % 60;

      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.stopCountdown();
          this.modal.close();
        } else {
          this.minutes--;
          this.seconds = 59;
          this.checkCountdown();
        }
      } else {
        this.seconds--;
        this.checkCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  ngOnDestroy() {
    this.stopCountdown();
  }
}
