import Dexie, { Collection, Table } from 'dexie';
import { Games } from '@componentpath/schema/interface.global';

export class IndexedDB extends Dexie {
    private games!: Table<Games, number>;

    constructor() {
        super('orianna');
        this.version(3).stores({
            games: '++id,title,created_at,popularity_point,rtp',
        });
    }

    async addGames(data: Games[]) {
        // await db.games.bulkPut(data);
    }

    async getGamesIndexedDB(
        page: number,
        perPage: number,
        providers: Array<any>,
        gameTypes: Array<any>,
        tags: Array<any>,
        searchValue: string,
        sortBy: string,
        sortType: "asc" | "desc"
    ){
        const collection = db.games
        .orderBy(sortBy)
        .filter((x: any) =>
            (x.keyword.toLowerCase().includes(searchValue) || x.provider_name.toLowerCase().includes(searchValue)) &&
            (gameTypes[0] !== '' ? gameTypes.includes(x.game_type) : x.game_type) &&
            (providers[0] !== '' ? providers.includes(x.provider) : x.provider) &&
            (tags[0] !== '' ? tags.some((z: any) => x.tags.indexOf(z) !== -1) : x.provider)
        );

        let sortCollection : Games[] = [];
        if(sortType === "asc"){
            sortCollection = await collection.offset((page - 1) * perPage).limit(perPage).toArray();
        } else {
            sortCollection = await collection.reverse().offset((page-1) * perPage).limit(perPage).toArray();
        }
        
        return sortCollection;
    }

    async dbGamesExists(){
        return await Dexie.exists('orianna')
    }

    async resetDatabase() {
        await db.transaction('rw', 'games', () => {
            this.games.clear();
        });
    }
    async deleteDatabase(){
        await db.delete();
    }
}

export const db = new IndexedDB();
