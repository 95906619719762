import { Component, Input, inject, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { BankCreatePopupComponent } from '@template/mps-basic/parts/bank-create-popup/bank-create-popup.component';

@Component({
  selector: 'app-bank-player-popup',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './bank-player-popup.component.html',
  styleUrl: './bank-player-popup.component.scss'
})
export class BankPlayerPopupComponent {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public request: RequestService,
    public auth: AuthService,
  ) { }

  @Input() bankCurrent: any = null;
  bankList: any = null;

  private modalService = inject(NgbModal);
  modal = inject(NgbActiveModal);

  userProfileData: any = this.auth.userProfileData;

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  keyword: string = '';
  bankShow: any = [];

  keywordSubmit(event: any) {
    this.keyword = event.target.value;
    this.changeFilter();
  }

  changeFilter() {
    this.bankShow = [];
    for (const value of this.bankList.data.records) {
      if (this.keyword === '') {
        this.bankShow.push(value);
      } else {
        if (value.account_number.toLowerCase().includes(this.keyword.toLowerCase()) || value.account_name.toLowerCase().includes(this.keyword.toLowerCase()) || value.system_bank.name.toLowerCase().includes(this.keyword.toLowerCase())) {
          this.bankShow.push(value);
        }
      }
    }
  }

  bankClick(value: any) {
    this.bankCurrent = value;
    this.popupClose();
  }

  bankCreateOpen(): void {
    const activeModal = this.modalService.open(BankCreatePopupComponent, { size: 'lg', keyboard: false, centered: true });
    activeModal.result.then(
      (result) => {
        if (result !== null) {
          this.bankShow = result.data.records;
        }
      }
    );
  }

  popupClose() {
    this.modal.close(this.bankCurrent);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.getBankPlayerList(this.auth.sessionValue, this.userProfileData._id).subscribe({
        next: (v) => {
          this.bankList = v;
          this.bankShow = this.bankList.data.records;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
    }
  }
}
